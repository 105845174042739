<template>
  <div class="ccc">
    <TopHead></TopHead>
    <logoNav></logoNav>
    <div class="uc_con">
      <iframe :src="src" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
  export default {
    name: "paymentDetail",
    data() {
      return {
        src:`https://manage.chinatdll.com/webroot/decision/view/report?viewlet=tdll/fx-payment-detail.cpt&op=view&creator_name=`

      }
    },
    created(){
      let name = sessionStorage.getItem('name');
      this.src += name
    },
    methods: {}
  }
</script>

<style scoped lang="scss">
  iframe{
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
  }
  .uc_con{
    height: calc(100vh - 110px - 56px - 60px);
    box-sizing: border-box;
  }
</style>
